/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@import '~bootstrap/scss/bootstrap';
@import './scss/vendor/bootstrap';
@import 'variables';
@import 'scss/abstract/fontface';
@import 'scss/base/base';
@import 'scss/components/buttons';
@import 'scss/components/typography';
@import 'scss/components/modals';
@import 'scss/components/toast';
@import 'scss/components/forms';
@import '~@ng-select/ng-select/themes/default.theme.css';

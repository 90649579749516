@font-face {
  font-family: 'HNCondensed';
  font-weight: 400;
  src: url('../../../src/assets/fonts/HelveticaNeueLTStd-Cn.otf');
}

@font-face {
  font-family: 'HNCondensedLight';
  font-weight: 200;
  src: url('../../../src/assets/fonts/HelveticaNeueLTStd-LtCn.otf');
}

@font-face {
  font-family: 'HNCondensedMedium';
  font-weight: 600;
  src: url('../../../src/assets/fonts/Helvetica-Neue-LT-Std-67-Medium-Condensed_22535.ttf')
    format('truetype');
}

@font-face {
  font-family: 'HNCondensedBold';
  font-weight: 800;
  src: url('../../../src/assets/fonts/HelveticaNeueLTStd-BdCn.otf');
}

@font-face {
  font-family: 'HNBold';
  font-weight: 800;
  src: url('../../../src/assets/fonts/Helvetica-Neue-LT-Std-75-Bold_22539.ttf')
    format('truetype');
}

$theme-colors: (
  primary: $primary,
  secondary: transparent,
  // success: '',
    // info: '',
    // warning: '',
    // danger: '',
    // light: '',
    // dark: '',
);

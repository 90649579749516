ng-select {
  .ng-select-container {
    padding: 0.6rem 0.6rem 0.4rem !important;
    height: auto !important;
    border: 1px solid $secondary !important;
    border-radius: 0 !important;
  }
  .ng-dropdown-panel {
    border: 1px solid $secondary !important;
    border-radius: 0 !important;
  }

  &.ng-invalid.ng-touched {
    border-color: $red;
  }

  &.ng-select-multiple {
    .ng-select-container {
      height: 44px !important;
      .ng-value-container {
        position: relative;
        .ng-placeholder {
          top: 4px;
        }

        .ng-value {
          background-color: $green !important;
          color: $white;
        }
      }
    }
  }
}

.custom-control-input {
  &:checked ~ .custom-control-label::before {
    background-color: $medium-gray;
    border-color: $medium-gray;
  }
}

.custom-input {
  padding: 0.6rem 0.6rem 0.4rem;
  border-radius: 0;
  border: 1px solid $secondary;
  width: 100%;

  &::placeholder {
    color: $medium-gray;
  }

  &.ng-invalid.ng-touched {
    border-color: $red;
  }
}

.switch-container {
  position: relative;

  .switch-text {
    max-width: 85%;
  }
}

.switch {
  position: absolute !important;
  right: 6px;
  top: 1px;

  &-label {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 1px;
    cursor: pointer;
    min-width: 40px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  &-input {
    visibility: hidden;
    & + .switch-controls {
      &:before {
        content: "";
        background: $light-gray;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 25px;
        width: 40px;
        border-radius: 16px;
        visibility: visible;
        &:disabled {
          opacity: 0.5;
        }
      }

      &:after {
        content: "";
        background: $white;
        position: absolute;
        left: 2px;
        top: 2px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        transition-duration: 0.28s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: left;
        visibility: visible;
      }
      &-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    &:checked + .switch-controls {
      &:before {
        background-color: $primary;
      }

      &:after {
        background-color: $light-gray;
        border-color: $light-gray;
        left: 18px;
      }
    }
  }
}

.abilitation {
  background-color: $red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;

  &.green {
    background-color: $green;
  }

  &.gray {
    background-color: $gray !important;
  }
}

.custom-input:disabled {
  padding: 0 !important;
  border: none !important;
  background-color: none !important;
  background: none !important;
  display: inline-block;
  width: 80%;
}

.ng-select-disabled > .ng-select-container {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  min-height: auto;

  .ng-value-container {
    padding-left: 0 !important;
    font-weight: bold;
    font-family: "HNCondensed";
  }
}
.ng-value-container {
  padding: 0 !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

@media (max-width: 991px) {
  .custom-option {
    font-size: 0;
  }
}

.form-group {
  text-align: left;
  margin-top: 0.3rem;
  label {
    color: #9e9d9d;
    display: block;
    text-align: left;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 0.2rem;
  }
}

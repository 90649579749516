@import '../../../node_modules/bootstrap/scss/buttons';

.button {
  @extend .btn;
  border: 1px solid;
  border-radius: $btn-border-radius;
  font-family: 'HNCondensedMedium', 'Lato', sans-serif;
  text-transform: uppercase;

  font-size: 1rem;
  padding: 0.3rem 1.5rem 0rem;
  & > * {
    vertical-align: top;
  }

  i {
    margin-right: 5px;
    font-size: 1rem;
    margin-top: 1px;
  }

  &:disabled {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $medium-gray;
  }

  &.big {
    font-size: 1.1rem;
    padding: 0.5rem 2rem 0.3rem;
    i {
      font-size: 1.1rem;
      margin-top: 3px;
    }
  }
}

.button-primary {
  @extend .button;
  border-color: $primary;
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: darken($primary, 5%);
    border-color: darken($primary, 5%);
    color: $white;
  }
  &:active {
    background-color: darken($primary, 5%);
  }
}

.button-secondary {
  @extend .button;
  border-color: $secondary;
  color: $secondary;

  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
  }
  &:active {
    background-color: darken($color: $black, $amount: 5%);
  }
}

.link {
  font-family: 'HNCondensedMedium';
  text-transform: uppercase;

  font-size: 1rem;
  &:hover {
    text-decoration: underline;
    color: darken($primary, 10%);
  }

  i {
    margin-right: 5px;
    font-size: 1.1rem;
    vertical-align: top;
    margin-top: 1px;
  }
}

.link-primary {
  @extend .link;
  color: $primary;
  font-size: 0.9rem;
}

.link-secondary {
  @extend .link;
  color: $secondary;
}

.small-link {
  font-family: 'HNCondensed';
  text-decoration: underline;
  &:hover {
    color: $dark-green;
  }
}

.small-link-primary {
  @extend .small-link;
  color: $primary;
}

@media (max-width: 767px) {
  .button,
  .button-primary,
  .button-secondary {
    padding: 0.3rem 0.7rem 0rem;
  }
}

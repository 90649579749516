@import 'variables';

* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background-color: $general-bg;
  font-size: 1rem;
  color: $text-color;
  font-family: 'HNCondensedLight';

  b {
    font-family: 'HNCondensedMedium';
  }
}

a {
  color: $primary;
  text-decoration: none;
  background-color: transparent;
}

h2 {
  color: $primary !important;
  font-size: 1.6rem;
  margin-bottom: 0.7rem;
}

@media (max-width: 991px) {
  body {
    padding-top: 30px;
  }
}

.ng-dropdown-panel {
  width: min-content !important;
  .ng-select div {
    width: min-content !important;
  }
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

h1 {
  color: $primary;
  font-family: 'HNCondensedLight';
  font-size: 2rem;
  text-transform: uppercase;
  border-bottom: 1px solid $primary;
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  z-index: 0;

  &:after {
    content: '';
    width: 210px;
    height: 3px;
    background-color: $primary;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}

h2 {
  font-family: 'HNCondensedLight';
  color: $secondary;
  font-size: 1.3rem;
  margin-bottom: 0;
}

h3 {
  font-family: 'HNCondensedLight';
  color: $medium-gray;
  font-size: 1.1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

h4 {
  font-family: 'HNCondensedMedium';
  color: $secondary;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

h5 {
  font-family: 'HNCondensedLight';
  color: $medium-gray;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
}

.custom-label {
  font-family: 'HNCondensedLight';
  color: $secondary;
  font-size: 0.9rem;
  text-transform: uppercase;
  display: block;
  margin-bottom: 0;
  margin-top: 1.5rem;
}

@media (max-width: 767px) {
  h1 {
    word-break: break-all;
  }
}
